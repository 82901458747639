<template>
  <v-footer color="blue darken-2">
    <v-icon class="red--text">mdi-glass-wine</v-icon>
    <v-spacer></v-spacer>
    <a class="white--text" href="http://www.st-andrew.org/" target="_blank"
      >St. Andrew the Apostle</a
    >
    <v-spacer></v-spacer>
    <v-icon class="amber--text">mdi-beer</v-icon>
  </v-footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>
